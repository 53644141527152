*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    /* overflow: hidden; */
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    z-index: -1;
}

.filler {
    width: 100vw;
    height: 100vh;
    color: white;
    transform: scale(0.25, 0.25);
    opacity: 0;
    position: fixed;
    top: 18vh;
    left: 0;
}

.secondary {
    color: white;
    margin-top: 400vh;
}